<template>
  <section>
    <GenerateInvoiceValuesPanel
      :invoiceTotalValue="invoiceTotalsPanel(invoiceListBySale)"
    />

    <aAlert
      class="f12 mb-20"
      type="info"
      message="Confira todos os dados antes de gerar a fatura."
      banner
    />

    <div v-for="(sale, i) in invoiceListBySale" :key="i">
      <aTable
        class="travel-table generate-invoices-table mb-0 relative"
        style="z-index: 2"
        :columns="columns"
        :data-source="sale"
        :pagination="false"
        :footer="false"
        :scroll="{ y: 800 }"
      >
        <div slot="sale_id" slot-scope="record">
          <aTooltip>
            <template #title> Contrato: {{ record.id }} </template>
            {{ record.sale_id }}
          </aTooltip>
        </div>

        <div slot="product" slot-scope="record">
          <div class="dotted-phrase upper">
            <a-tooltip :title="record.product">
              {{ record.product }}
            </a-tooltip>
          </div>
        </div>

        <div slot="traveller_name" slot-scope="record">
          <div class="truncate upper">
            <aTooltip v-if="record.product_type == 'flight'" placement="left">
              <template #title>
                <div v-html="record.traveller_name"></div
              ></template>
              {{ record.traveller_name }}
            </aTooltip>

            <aTooltip
              v-if="['hotel', 'service'].includes(record.product_type)"
              placement="left"
            >
              <template #title> <div v-html="record.product"></div></template>
              {{ record.product }}
            </aTooltip>
          </div>
        </div>

        <div slot="incomings" slot-scope="record">
          <GenerateInvoiceModalEditRowSection
            :contract="record"
            :editContractId="editContractId"
            column="incomings"
            @onClickChangeEditId="onClickChangeEditId"
            @updateContractsList="updateContractsList"
          />
        </div>

        <div slot="to_pay" slot-scope="record">
          <GenerateInvoiceModalEditRowSection
            :contract="record"
            :editContractId="editContractId"
            column="to_pay"
            @onClickChangeEditId="onClickChangeEditId"
            @updateContractsList="updateContractsList"
          />
        </div>

        <div slot="ticket_value" slot-scope="record">
          <GenerateInvoiceModalEditRowSection
            :contract="record"
            :editContractId="editContractId"
            column="ticket_value"
            @onClickChangeEditId="onClickChangeEditId"
            @updateContractsList="updateContractsList"
          />
        </div>

        <div slot="ticket_tax_value" slot-scope="record">
          <GenerateInvoiceModalEditRowSection
            :contract="record"
            :editContractId="editContractId"
            column="ticket_tax_value"
            @onClickChangeEditId="onClickChangeEditId"
            @updateContractsList="updateContractsList"
          />
        </div>

        <div slot="extra_fee_value" slot-scope="record">
          <GenerateInvoiceModalEditRowSection
            :contract="record"
            :editContractId="editContractId"
            column="extra_fee_value"
            @onClickChangeEditId="onClickChangeEditId"
            @updateContractsList="updateContractsList"
          />
        </div>

        <div slot="status" slot-scope="record">
          <div
            class="status"
            :id="`contract-${record.id}-invoice-status-${record.product_id}-${record.traveller_id}`"
          >
            ---
          </div>
        </div>
      </aTable>

      <aTable
        class="travel-table generate-invoices-table fw-600 cblack relative"
        style="margin-top: -25px; z-index: 1"
        :columns="[
          {
            title: false,
            dataIndex: 'fakeCol1',
            key: 'fakeCol1',
            width: 70,
          },
          {
            title: false,
            dataIndex: 'fakeCol2',
            key: 'fakeCol2',
            width: 90,
          },
          {
            title: false,
            dataIndex: 'fakeCol3',
            key: 'fakeCol3',
            width: 120,
          },
          {
            title: false,
            dataIndex: 'totalTicket',
            key: 'totalTicket',
            width: 120,
          },
          {
            title: false,
            dataIndex: 'totalTicketTax',
            key: 'totalTicketTax',
            width: 130,
          },
          {
            title: false,
            dataIndex: 'totalIncoming',
            key: 'totalIncoming',
            width: 120,
          },
          {
            title: false,
            dataIndex: 'totalToPay',
            key: 'totalToPay',
            width: 120,
          },

          {
            title: false,
            dataIndex: 'totalExtraFee',
            key: 'totalExtraFee',
            width: 120,
          },
          {
            title: false,
            dataIndex: 'totalBalance',
            key: 'totalBalance',
            width: 100,
          },
        ]"
        :data-source="totalsListBySale(sale)"
        :header="false"
        :footer="false"
        :pagination="false"
        :scroll="{ y: 800 }"
      />
    </div>

    <aTable
      class="travel-table total generate-invoices-table fw-600 cblack relative"
      style="margin-top: -10px; z-index: 0; border-bottom: 2px solid #aaa"
      :columns="[
        {
          title: false,
          dataIndex: 'fakeCol1',
          key: 'fakeCol1',
          width: 160,
        },

        {
          title: false,
          dataIndex: 'fakeCol3',
          key: 'fakeCol3',
          width: 120,
        },
        {
          title: false,
          dataIndex: 'totalTicket',
          key: 'totalTicket',
          width: 120,
        },
        {
          title: false,
          dataIndex: 'totalTicketTax',
          key: 'totalTicketTax',
          width: 130,
        },
        {
          title: false,
          dataIndex: 'totalIncoming',
          key: 'totalIncoming',
          width: 120,
        },
        {
          title: false,
          dataIndex: 'totalToPay',
          key: 'totalToPay',
          width: 120,
        },

        {
          title: false,
          dataIndex: 'totalExtraFee',
          key: 'totalExtraFee',
          width: 120,
        },
        {
          title: false,
          dataIndex: 'totalBalance',
          key: 'totalBalance',
          width: 100,
        },
      ]"
      :data-source="totalGeneralList(generalInvoiceList)"
      :header="false"
      :footer="false"
      :pagination="false"
      :scroll="{ y: 800 }"
    />

    <GenerateInvoiceForm
      :invoiceTotalValue="invoiceTotalsPanel(invoiceListBySale)"
      :tempInvoice="tempInvoice"
      :generateInvoicesList="generateInvoicesList"
      @onFinishGenerateInvoice="onFinishGenerateInvoice"
      @onClickIsLoadingAction="onClickIsLoadingAction"
    />
  </section>
</template>

<script>
import _ from "lodash";
import { parse } from "vue-currency-input";
import formatThings from "@/mixins/general/formatThings";

import GenerateInvoiceModalEditRowSection from "../sections/GenerateInvoiceModalEditRowSection.vue";
import GenerateInvoiceValuesPanel from "../sections/GenerateInvoiceValuesPanel.vue";
import GenerateInvoiceForm from "../sections/GenerateInvoiceForm.vue";

export default {
  name: "BillsToPayModal",
  props: {
    invoicesInQueue: Array,
    tempInvoice: Object,
  },
  components: {
    GenerateInvoiceModalEditRowSection,
    GenerateInvoiceValuesPanel,
    GenerateInvoiceForm,
  },
  mixins: [formatThings],
  data() {
    return {
      editContractId: "",
      loadingGenerateInvoice: false,
      generateInvoicesList: [],
      invoiceListBySale: [],
      generalInvoiceList: [],
      columns: [
        {
          title: "Venda",
          scopedSlots: { customRender: "sale_id" },
          width: 70,
        },
        {
          title: "Loc.",
          dataIndex: "locator",
          key: "locator",
          width: 90,
        },
        {
          title: "Viajantes/Produtos",
          scopedSlots: { customRender: "traveller_name" },
          width: 120,
        },
        {
          title: "Valor",
          key: "ticket_value",
          width: 120,
          scopedSlots: { customRender: "ticket_value" },
        },
        {
          title: "Tx. Embarque",
          key: "ticket_tax_value",
          width: 130,
          scopedSlots: { customRender: "ticket_tax_value" },
        },
        {
          title: "A Receber",
          key: "incomings",
          width: 120,
          scopedSlots: { customRender: "incomings" },
        },
        {
          title: "A Pagar",
          key: "to_pay",
          width: 120,
          scopedSlots: { customRender: "to_pay" },
        },
        {
          title: "Taxa extra",
          width: 120,
          scopedSlots: { customRender: "extra_fee_value" },
        },
        {
          title: "Status/Saldo",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
      ],
    };
  },
  mounted() {
    this.generateInvoicesList = this.invoicesInQueue;
    this.invoiceListBySale = _.groupBy(this.invoicesInQueue, "invoice_group");
    this.generalInvoiceList = this.invoicesInQueue;
  },
  methods: {
    totalsListBySale(arr) {
      let totalTicket = 0;
      let totalTicketTax = 0;
      let totalToPay = 0;
      let totalIncoming = 0;
      let totalIncomingBalance = 0;
      let totalExtraFee = 0;

      if (arr.length) {
        arr.forEach((passenger) => {
          if (typeof passenger.ticket_value === "number") {
            totalTicket += passenger.ticket_value;
          } else {
            totalTicket += parse(passenger.ticket_value);
          }

          if (typeof passenger.ticket_tax_value === "number") {
            totalTicketTax += passenger.ticket_tax_value;
          } else {
            totalTicketTax += parse(passenger.ticket_tax_value);
          }

          if (typeof passenger.to_pay === "number") {
            totalToPay += passenger.to_pay;
          } else {
            totalToPay += parse(passenger.to_pay);
          }

          if (typeof passenger.incomings === "number") {
            if (passenger.flightValueIsEqualPaymentValues) {
              totalIncomingBalance += 0;
            } else {
              totalIncomingBalance += passenger.incomings;
            }
          } else {
            if (passenger.flightValueIsEqualPaymentValues) {
              totalIncomingBalance += 0;
            } else {
              totalIncomingBalance += parse(passenger.incomings);
            }
          }

          if (typeof passenger.incomings === "number") {
            totalIncoming += passenger.incomings;
          } else {
            totalIncoming += parse(passenger.incomings);
          }

          if (typeof passenger.extra_fee_value === "number") {
            totalExtraFee += passenger.extra_fee_value;
          } else {
            totalExtraFee += parse(passenger.extra_fee_value);
          }
        });
      }

      return [
        {
          fakeCol1: "TOTAL",
          fakeCol2: "",
          fakeCol3: "",
          totalIncoming: this.formatPricePtBr(totalIncoming),
          totalToPay: this.formatPricePtBr(totalToPay),
          totalTicket: this.formatPricePtBr(totalTicket),
          totalTicketTax: this.formatPricePtBr(totalTicketTax),
          totalExtraFee: this.formatPricePtBr(totalExtraFee),
          totalBalance: this.formatPricePtBr(
            totalToPay - totalExtraFee - totalIncomingBalance
          ),
        },
      ];
    },
    onClickIsLoadingAction(status) {
      this.$emit("onClickIsLoadingAction", status);
    },
    totalGeneralList(arr) {
      let totalTicket = 0;
      let totalTicketTax = 0;
      let totalToPay = 0;
      let totalIncoming = 0;
      let totalIncomingBalance = 0;
      let totalExtraFee = 0;

      if (arr.length) {
        arr.forEach((passenger) => {
          if (typeof passenger.ticket_value === "number") {
            totalTicket += passenger.ticket_value;
          } else {
            totalTicket += parse(passenger.ticket_value);
          }

          if (typeof passenger.ticket_tax_value === "number") {
            totalTicketTax += passenger.ticket_tax_value;
          } else {
            totalTicketTax += parse(passenger.ticket_tax_value);
          }

          if (typeof passenger.to_pay === "number") {
            totalToPay += passenger.to_pay;
          } else {
            totalToPay += parse(passenger.to_pay);
          }

          if (typeof passenger.incomings === "number") {
            if (passenger.flightValueIsEqualPaymentValues) {
              totalIncomingBalance += 0;
            } else {
              totalIncomingBalance += passenger.incomings;
            }
          } else {
            if (passenger.flightValueIsEqualPaymentValues) {
              totalIncomingBalance += 0;
            } else {
              totalIncomingBalance += parse(passenger.incomings);
            }
          }

          if (typeof passenger.incomings === "number") {
            totalIncoming += passenger.incomings;
          } else {
            totalIncoming += parse(passenger.incomings);
          }

          if (typeof passenger.extra_fee_value === "number") {
            totalExtraFee += passenger.extra_fee_value;
          } else {
            totalExtraFee += parse(passenger.extra_fee_value);
          }
        });
      }

      return [
        {
          fakeCol1: "TOTAL GERAL",
          fakeCol3: "",
          totalIncoming: this.formatPricePtBr(totalIncoming),
          totalToPay: this.formatPricePtBr(totalToPay),
          totalTicket: this.formatPricePtBr(totalTicket),
          totalTicketTax: this.formatPricePtBr(totalTicketTax),
          totalExtraFee: this.formatPricePtBr(totalExtraFee),
          totalBalance: this.formatPricePtBr(
            totalToPay - totalExtraFee - totalIncomingBalance
          ),
        },
      ];
    },
    invoiceTotalsPanel(arr) {
      let total = 0;
      let totalToPay = 0;
      let totalIncomings = 0;
      let totalExtraFees = 0;

      Object.values(arr).forEach((sale) => {
        sale.forEach((passenger) => {
          if (typeof passenger.ticket_value === "number") {
            total += passenger.ticket_value;
          } else {
            total += parse(passenger.ticket_value);
          }

          if (typeof passenger.ticket_tax_value === "number") {
            total += passenger.ticket_tax_value;
          } else {
            total += parse(passenger.ticket_tax_value);
          }

          if (typeof passenger.to_pay === "number") {
            totalToPay += passenger.to_pay;
          } else {
            totalToPay += parse(passenger.to_pay);
          }

          console.log(
            passenger.sale_id,
            "passenger",
            passenger.flightValueIsEqualPaymentValues,
            passenger.totalBankTransfer,
            passenger.flightTotalValue,
            passenger.incomings
          );

          if (typeof passenger.incomings === "number") {
            if (passenger.flightValueIsEqualPaymentValues) {
              totalIncomings += 0;
            } else {
              totalIncomings += passenger.incomings;
            }
          } else {
            if (passenger.flightValueIsEqualPaymentValues) {
              totalIncomings += 0;
            } else {
              totalIncomings += parse(passenger.incomings);
            }
          }

          if (typeof passenger.extra_fee_value === "number") {
            totalExtraFees += passenger.extra_fee_value;
          } else {
            totalExtraFees += parse(passenger.extra_fee_value);
          }
        });
      });

      return {
        total,
        formattedTotal: this.formatPricePtBr(total),
        totalToPay: totalToPay,
        formattedTotalToPay: this.formatPricePtBr(totalToPay),
        totalIncomings,
        formattedTotalIncomings: this.formatPricePtBr(totalIncomings),
        totalExtraFees: totalExtraFees,
        totalBalance: totalToPay - totalExtraFees - totalIncomings, // totalBalance
        formattedTotalBalance: this.formatPricePtBr(
          totalToPay - totalExtraFees - totalIncomings
        ),
      };
    },
    onFinishGenerateInvoice() {
      this.$emit("onFinishGenerateInvoice");
    },
    onClickChangeEditId(editId) {
      this.editContractId = editId;
    },
    updateContractsList(value, selectedContract, column) {
      this.generateInvoicesList.forEach((contract) => {
        if (
          contract.id === selectedContract.id &&
          contract.product_id === selectedContract.product_id &&
          contract.traveller_id === selectedContract.traveller_id
        ) {
          if (column === "incomings") contract.incomings = value;
          if (column === "to_pay") contract.to_pay = value;
          if (column === "ticket_value") contract.ticket_value = value;
          if (column === "ticket_tax_value") contract.ticket_tax_value = value;
          if (column === "extra_fee_value") contract.extra_fee_value = value;
        }
      });

      this.editContractId = "";
    },
  },
};
</script>

<style lang="sass">
.generate-invoices-table.total td
  border-top: 2px solid #aaa
.generate-invoices-table td
  padding: 4px 3px 4px 3px !important
.generate-invoices-table thead tr th
  padding: 4px 3px 4px 3px !important

.generate-invoices-table
  .ant-table-pagination
    margin-bottom: 0 !important
    padding: 0
</style>

<style lang="sass" scoped>
.invoice-value
  font-size: 16px
  font-weight: 600
  letter-spacing: -0.5px
  margin-top: 20px
  text-align: center
  b
    display: block
</style>
