<template>
  <a-row class="haya-panels mb-20" :gutter="20">
    <a-col :span="6">
      <div class="box">
        <div class="title"></div>
        <div class="total">{{ invoiceTotalValue.formattedTotal }}</div>
        <div class="txt"></div>
        <div class="footer">Valor total da fatura</div>
      </div>
    </a-col>
    <a-col :span="6">
      <div class="box">
        <div class="title"></div>
        <div class="total">
          {{ invoiceTotalValue.formattedTotalToPay }}
        </div>
        <div class="txt"></div>
        <div class="footer">Valor à pagar</div>
      </div>
    </a-col>
    <a-col :span="6">
      <div class="box">
        <div class="title"></div>
        <div class="total">
          {{ invoiceTotalValue.formattedTotalIncomings }}
        </div>
        <div class="txt"></div>
        <div class="footer">Valor a Receber</div>
      </div>
    </a-col>
    <a-col :span="6">
      <div class="box">
        <div class="title"></div>
        <div class="total">
          {{ invoiceTotalValue.formattedTotalBalance }}
        </div>
        <div class="txt"></div>
        <div class="footer">Saldo</div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "GenerateInvoiceValuesPanel",
  props: {
    invoiceTotalValue: Object,
  },
};
</script>
