var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aForm',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{staticClass:"mt-20",attrs:{"span":24}},[_c('aAlert',{staticClass:"f12 mb-10",attrs:{"type":"info","message":"Preencha os campos abaixo para gerar uma fatura e um evento no calendário.","banner":""}})],1),_vm._v(" "),_c('aCol',{staticClass:"mb-30",attrs:{"span":24}},[_c('aRow',[_c('aCol',{attrs:{"span":3}},[_c('b',{staticClass:"f12"},[_vm._v("Tipo de Fatura:")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.tempInvoice.type))])]),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_c('b',{staticClass:"f12"},[_vm._v("Empresa:")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.tempInvoice.company.trading_name))])]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('b',{staticClass:"f12"},[_vm._v("Filial:")]),_vm._v(" "),_c('div',[_vm._v("\n            "+_vm._s(_vm.tempInvoice.company_branch.id)+" -\n            "+_vm._s(_vm.tempInvoice.company_branch.name)+"\n          ")])]),_vm._v(" "),_c('aCol',{attrs:{"span":8}},[_c('b',{staticClass:"f12"},[_vm._v("Nome da Fatura:")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.tempInvoice.name))])])],1)],1),_vm._v(" "),(_vm.tempInvoice.type)?_c('aCol',{attrs:{"span":6}},[_c('HayaSelectField',{attrs:{"label":`Forma de ${_vm.tempInvoice.type}`,"fieldKey":"payment_methods","list":_vm.paymentMethods,"required":true,"form":_vm.form,"allowClear":true,"showSearch":true}})],1):_vm._e(),_vm._v(" "),(_vm.tempInvoice.type)?_c('aCol',{attrs:{"span":8}},[_c('HayaRadioField',{attrs:{"label":`Status do ${_vm.tempInvoice.type}`,"optionFilterProp":"txt","fieldKey":"status","form":_vm.form,"required":true,"options":[
          {
            label: 'Pendente',
            value: 'Pendente',
            disabled: false,
          },
          {
            label: 'Pago',
            value: 'Pago',
            disabled: _vm.tempInvoice.type === 'Recebimento' ? true : false,
          },

          {
            label: 'Recebido',
            value: 'Recebido',
            disabled: _vm.tempInvoice.type === 'Pagamento' ? true : false,
          },
        ]}})],1):_vm._e(),_vm._v(" "),(
        _vm.form.getFieldValue(`payment_methods`) === 'Cartão de Crédito' &&
        _vm.form.getFieldValue(`status`) === 'Pago' &&
        _vm.tempInvoice.type
      )?_c('aCol',{attrs:{"span":6}},[_c('HayaSelectField',{attrs:{"label":"Cartão de Crédito","fieldKey":"company_credit_card_id","list":_vm.companyCreditCardList,"required":true,"form":_vm.form,"allowClear":true,"showSearch":true}})],1):_vm._e(),_vm._v(" "),(
        ['Transferência Bancária/PIX', 'Boleto Bancário'].includes(
          _vm.form.getFieldValue(`payment_methods`)
        ) &&
        _vm.form.getFieldValue(`status`) === 'Pago' &&
        _vm.tempInvoice.type
      )?_c('aCol',{attrs:{"span":6}},[_c('HayaSelectField',{attrs:{"label":"Conta Bancária","fieldKey":"bank_id","list":_vm.companyBanksList,"required":true,"form":_vm.form,"allowClear":true,"showSearch":true}})],1):_vm._e(),_vm._v(" "),(_vm.tempInvoice.type)?_c('aCol',{attrs:{"span":5}},[_c('HayaDatePickerField',{attrs:{"label":`Data do ${_vm.tempInvoice.type}`,"placeholder":"Selecione...","fieldKey":"pay_day","required":true,"form":_vm.form,"preIcon":true}})],1):_vm._e(),_vm._v(" "),(_vm.tempInvoice.type)?_c('aCol',{attrs:{"span":5}},[_c('HayaDatePickerField',{attrs:{"label":`Data Vencimeto do ${_vm.tempInvoice.type}`,"placeholder":"Selecione...","fieldKey":"due_date","required":true,"form":_vm.form,"preIcon":true}})],1):_vm._e(),_vm._v(" "),_c('aCol',{staticClass:"a-center",attrs:{"span":24}},[_c('aDivider',{staticClass:"mt-0"}),_vm._v(" "),_c('div',{staticClass:"f16",domProps:{"innerHTML":_vm._s(_vm.invoiceDetails())}})],1),_vm._v(" "),_c('aCol',{staticClass:"a-center",attrs:{"span":24}},[_c('aDivider'),_vm._v(" "),_c('aButton',{staticClass:"ml-20",attrs:{"type":"primary","html-type":"submit","loading":_vm.loadingGenerateInvoice,"disabled":_vm.loadingGenerateInvoice ? true : false}},[_vm._v("\n        Gerar fatura\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }