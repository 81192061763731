<template>
  <aForm @submit.prevent="onSubmitForm" :form="form">
    <aRow :gutter="20">
      <aCol class="mt-20" :span="24">
        <aAlert
          class="f12 mb-10"
          type="info"
          message="Preencha os campos abaixo para gerar uma fatura e um evento no calendário."
          banner
        />
      </aCol>

      <aCol class="mb-30" :span="24">
        <aRow>
          <aCol :span="3">
            <b class="f12">Tipo de Fatura:</b>
            <div>{{ tempInvoice.type }}</div>
          </aCol>

          <aCol :span="3">
            <b class="f12">Empresa:</b>
            <div>{{ tempInvoice.company.trading_name }}</div>
          </aCol>
          <aCol :span="4">
            <b class="f12">Filial:</b>
            <div>
              {{ tempInvoice.company_branch.id }} -
              {{ tempInvoice.company_branch.name }}
            </div>
          </aCol>
          <aCol :span="8">
            <b class="f12">Nome da Fatura:</b>
            <div>{{ tempInvoice.name }}</div>
          </aCol>
        </aRow>
      </aCol>

      <aCol v-if="tempInvoice.type" :span="6">
        <HayaSelectField
          :label="`Forma de ${tempInvoice.type}`"
          fieldKey="payment_methods"
          :list="paymentMethods"
          :required="true"
          :form="form"
          :allowClear="true"
          :showSearch="true"
        />
      </aCol>

      <aCol v-if="tempInvoice.type" :span="8">
        <HayaRadioField
          :label="`Status do ${tempInvoice.type}`"
          optionFilterProp="txt"
          fieldKey="status"
          :form="form"
          :required="true"
          :options="[
            {
              label: 'Pendente',
              value: 'Pendente',
              disabled: false,
            },
            {
              label: 'Pago',
              value: 'Pago',
              disabled: tempInvoice.type === 'Recebimento' ? true : false,
            },

            {
              label: 'Recebido',
              value: 'Recebido',
              disabled: tempInvoice.type === 'Pagamento' ? true : false,
            },
          ]"
        />
      </aCol>

      <aCol
        v-if="
          form.getFieldValue(`payment_methods`) === 'Cartão de Crédito' &&
          form.getFieldValue(`status`) === 'Pago' &&
          tempInvoice.type
        "
        :span="6"
      >
        <HayaSelectField
          label="Cartão de Crédito"
          fieldKey="company_credit_card_id"
          :list="companyCreditCardList"
          :required="true"
          :form="form"
          :allowClear="true"
          :showSearch="true"
        />
      </aCol>

      <aCol
        v-if="
          ['Transferência Bancária/PIX', 'Boleto Bancário'].includes(
            form.getFieldValue(`payment_methods`)
          ) &&
          form.getFieldValue(`status`) === 'Pago' &&
          tempInvoice.type
        "
        :span="6"
      >
        <HayaSelectField
          label="Conta Bancária"
          fieldKey="bank_id"
          :list="companyBanksList"
          :required="true"
          :form="form"
          :allowClear="true"
          :showSearch="true"
        />
      </aCol>

      <aCol v-if="tempInvoice.type" :span="5">
        <HayaDatePickerField
          :label="`Data do ${tempInvoice.type}`"
          placeholder="Selecione..."
          fieldKey="pay_day"
          :required="true"
          :form="form"
          :preIcon="true"
        />
      </aCol>

      <aCol v-if="tempInvoice.type" :span="5">
        <HayaDatePickerField
          :label="`Data Vencimeto do ${tempInvoice.type}`"
          placeholder="Selecione..."
          fieldKey="due_date"
          :required="true"
          :form="form"
          :preIcon="true"
        />
      </aCol>

      <aCol :span="24" class="a-center">
        <aDivider class="mt-0" />
        <div class="f16" v-html="invoiceDetails()"></div>
      </aCol>

      <aCol :span="24" class="a-center">
        <aDivider />
        <aButton
          class="ml-20"
          type="primary"
          html-type="submit"
          :loading="loadingGenerateInvoice"
          :disabled="loadingGenerateInvoice ? true : false"
        >
          Gerar fatura
        </aButton>
      </aCol>
    </aRow>
  </aForm>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaDatePickerField from "@/components/general/fields/HayaDatePickerField.vue";
import HayaRadioField from "@/components/general/fields/HayaRadioField.vue";

export default {
  name: "GenerateInvoiceForm",
  props: {
    invoiceTotalValue: Object,
    tempInvoice: Object,
    generateInvoicesList: Array,
  },
  components: {
    HayaSelectField,
    HayaDatePickerField,
    HayaRadioField,
  },
  mixins: [formatThings],
  data() {
    return {
      form: this.$form.createForm(this),
      paymentMethods: [],
      companyCreditCardList: [],
      companyBanksList: [],
      loadingGenerateInvoice: false,
    };
  },
  mounted() {
    this.getBankAccounts(this.tempInvoice.company.id);
    this.getCreditCards(this.tempInvoice.company.id);

    this.$http
      .get(
        `/category-entry/list?page=1&per_page=10&category_id=14&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.paymentMethods = data.data.map(({ id, name }) => {
          return {
            label: `${id != 0 ? id + " - " : ""} ${name} `,
            value: name,
          };
        });
      });
  },
  methods: {
    invoiceDetails() {
      let theType = "";

      if (this.tempInvoice.type === "Pagamento")
        theType = `de <b>PAGAMENTO</b>`;

      if (this.tempInvoice.type === "Recebimento") theType = `<b>À RECEBER</b>`;

      return theType && this.invoiceTotalValue.totalBalance !== 0
        ? `Gerar fatura ${theType} no valor de <b>${this.formatPricePtBr(
            this.invoiceTotalValue.totalBalance
          )}</b>.`
        : "";
    },
    getCreditCards(companyId) {
      this.$http
        .get(
          `/company-credit-card/list?page=1&per_page=100&status=Ativo&company_id=${companyId}`
        )
        .then(({ data }) => {
          this.companyCreditCardList = data.data.map(
            ({ id, card_name, card_number }) => {
              return {
                label: `${
                  id != 0 ? id + " - " : ""
                } ${card_name} (${card_number}) `,
                value: id,
              };
            }
          );
        });
    },
    getBankAccounts(companyId) {
      this.$http
        .get(
          `/company-bank-accounts/list?page=1&per_page=100&status=Ativo&company_id=${companyId}`
        )
        .then(({ data }) => {
          this.companyBanksList = data.data.map(({ id, name }) => {
            return {
              label: `${id != 0 ? id + " - " : ""} ${name} `,
              value: id,
            };
          });
        });
    },
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.module = "finance";
        values.company_branch_id = this.tempInvoice.company_branch.id;
        values.company_id = this.tempInvoice.company.id;
        values.type = this.tempInvoice.type;
        values.name = this.tempInvoice.name;
        values.temp_invoice_id = this.tempInvoice.id;
        values.priority = "Alta";
        values.value = this.invoiceTotalValue.total;
        values.to_pay_value = this.invoiceTotalValue.totalToPay;
        values.incoming_value = this.invoiceTotalValue.totalIncomings;
        values.balance_value = this.invoiceTotalValue.totalBalance;
        values.description = "";
        values.product_supplier_id = this.tempInvoice.product_supplier_id;

        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        if (!err) {
          this.loadingGenerateInvoice = true;
          this.$emit("onClickIsLoadingAction", true);
          this.createInvoice(values);
        }
      });
    },
    createInvoice(values) {
      this.$http
        .post("/invoice/create", values)
        .then(({ data }) => {
          this.$message.success(`Fatura ID ${data.id} gerada com sucesso!`, 3);

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: data.id,
            module: "invoice",
            action: "create",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} cadastrou uma nova fatura ID ${data.id}.`,
          });

          this.createEvent(values, data.id);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingGenerateInvoice = false;
        });
    },
    createEvent(values, invoiceId) {
      values.invoice_id = invoiceId;
      values.date = values.due_date;
      values.payday = values.pay_day;
      values.value = this.invoiceTotalValue.totalBalance;

      this.$http
        .post("/event/create", values)
        .then(({ data }) => {
          this.$message.success(`Evento ID ${data.id} criado com sucesso!`, 3);

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: data.id,
            module: "event",
            action: "create",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} cadastrou um novo evento ID ${data.id} a partir da página de geração de faturas.`,
          });

          this.updateContractsWithGeneratedInvoice(invoiceId, data.id, values);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingGenerateInvoice = false;
        });
    },
    async updateTempInvoice() {
      try {
        const data = await this.$http.post(`/temporary-invoice/update-field`, {
          id: this.tempInvoice.id,
          field: "status",
          value: "Finalizado",
        });

        return data;
      } catch (e) {
        e;
      }
    },
    async updateContractsWithGeneratedInvoice(invoiceId, eventId, invoiceData) {
      for (const contract of this.generateInvoicesList) {
        try {
          await this.updateContractsAsyncOperation(
            contract,
            invoiceId,
            eventId,
            invoiceData
          );
        } catch (e) {
          console.log(e);
        }
      }
      await this.finishLoadingAfterAllOperations();
    },
    async updateContractsAsyncOperation(
      contract,
      invoiceId,
      eventId,
      invoiceData
    ) {
      document.getElementById(
        `contract-${contract.id}-invoice-status-${contract.product_id}-${
          contract.traveller_id ?? 0
        }`
      ).innerHTML = "Atualizando...";

      let constractData = {};

      if (contract.product_type == "flight") {
        constractData = {
          id: contract.id,
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_invoice_id`]:
            invoiceId,
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_event_id`]:
            eventId,
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_invoice_status`]:
            invoiceData.status,
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_paid_at`]:
            invoiceData.date,
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_to_pay_value`]:
            `${contract.to_pay}`.replace(".", ","),
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_incoming_value`]:
            `${contract.incomings}`.replace(".", ","),
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_balance_value`]:
            `${contract.incomings - contract.to_pay}`.replace(".", ","),
        };
      } else {
        constractData = {
          id: contract.id,
          [`contract_finances_${contract.product_type}_${contract.product_id}_invoice_id`]:
            invoiceId,
          [`contract_finances_${contract.product_type}_${contract.product_id}_event_id`]:
            eventId,
          [`contract_finances_${contract.product_type}_${contract.product_id}_invoice_status`]:
            invoiceData.status,
          [`contract_finances_${contract.product_type}_${contract.product_id}_paid_at`]:
            invoiceData.date,
        };
      }

      if (invoiceData.status == "Pago" || invoiceData.status == "Recebido") {
        constractData[
          `contract_finances_${contract.product_type}_${contract.product_id}_audit_status`
        ] = "Realizado";

        constractData[
          `contract_finances_${contract.product_type}_${contract.product_id}_audit_user_id`
        ] = this.$store.state.userData.id;
      }

      await this.$http
        .post(`/contract/update-multi-meta`, constractData)
        .then(() => {
          document.getElementById(
            `contract-${contract.id}-invoice-status-${contract.product_id}-${
              contract.traveller_id ?? 0
            }`
          ).innerHTML = "<font class='cgreen'>Sucesso</font>";
        });
    },
    async finishLoadingAfterAllOperations() {
      this.loadingGenerateInvoice = false;
      this.updateTempInvoice();
      setTimeout(() => {
        this.$emit("onFinishGenerateInvoice");
        this.$emit("onClickIsLoadingAction", false);
      }, 500);
    },
  },
};
</script>
