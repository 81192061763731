var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('aForm',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('aRow',{attrs:{"gutter":10}},[_c('aCol',{attrs:{"span":24}},[_c('HayaSelectField',{attrs:{"label":"Modalidade","fieldKey":"type","list":[
            {
              label: 'Pagamento',
              value: 'Pagamento',
            },
            {
              label: 'Recebimento',
              value: 'Recebimento',
            },
          ],"required":true,"form":_vm.form,"allowClear":true,"showSearch":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":10}},[_c('HayaSelectField',{attrs:{"label":"Empresa","fieldKey":"company_id","list":_vm.companiesList,"required":true,"form":_vm.form,"allowClear":true,"showSearch":true},on:{"onChangeSelectField":_vm.getCompanyBranchesOnChange}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":14}},[_c('HayaSelectField',{attrs:{"label":"Filial","fieldKey":"company_branch_id","fieldClass":_vm.companyBranchesList.length === 0 ? 'readonly' : '',"list":_vm.companyBranchesList,"required":true,"form":_vm.form,"allowClear":true,"showSearch":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('HayaSelectField',{attrs:{"label":"Fornecedor","fieldKey":"product_supplier_id","list":_vm.productSupplier.list.map((item) => {
              item.label = `${item.id} - ${item.trading_name}`;
              item.value = item.id;
              return item;
            }),"required":true,"form":_vm.form,"allowClear":true,"showSearch":true,"optionFilterProp":"txt"},on:{"onChangeSelectField":_vm.onSelectProductSupplier}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('HayaTextField',{attrs:{"label":"Nome da fatura","placeholder":"Escreva...","fieldKey":"name","required":true,"form":_vm.form}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('div',{staticClass:"travel-input"},[_c('aFormItem',[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v("Período")]),_vm._v(" "),_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `period`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório.',
                    },
                  ],
                },
              ]),expression:"[\n                `period`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório.',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"format":['DD/MM/YYYY'],"value-format":'YYYY-MM-DD'}})],1)],1)]),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('aButton',{attrs:{"type":"primary","block":"","html-type":"submit","loading":_vm.loadingCreateTempInvoice}},[_vm._v("\n          Cadastrar fatura temporária\n        ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }