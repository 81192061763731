<template>
  <section>
    <aForm @submit.prevent="onSubmitForm" :form="form">
      <aRow :gutter="10">
        <aCol :span="24">
          <HayaSelectField
            label="Modalidade"
            fieldKey="type"
            :list="[
              {
                label: 'Pagamento',
                value: 'Pagamento',
              },
              {
                label: 'Recebimento',
                value: 'Recebimento',
              },
            ]"
            :required="true"
            :form="form"
            :allowClear="true"
            :showSearch="true"
          />
        </aCol>

        <aCol :span="10">
          <HayaSelectField
            label="Empresa"
            fieldKey="company_id"
            :list="companiesList"
            :required="true"
            :form="form"
            :allowClear="true"
            :showSearch="true"
            @onChangeSelectField="getCompanyBranchesOnChange"
          />
        </aCol>

        <aCol :span="14">
          <HayaSelectField
            label="Filial"
            fieldKey="company_branch_id"
            :fieldClass="companyBranchesList.length === 0 ? 'readonly' : ''"
            :list="companyBranchesList"
            :required="true"
            :form="form"
            :allowClear="true"
            :showSearch="true"
          />
        </aCol>

        <aCol :span="24">
          <HayaSelectField
            label="Fornecedor"
            fieldKey="product_supplier_id"
            :list="
              productSupplier.list.map((item) => {
                item.label = `${item.id} - ${item.trading_name}`;
                item.value = item.id;
                return item;
              })
            "
            :required="true"
            :form="form"
            :allowClear="true"
            :showSearch="true"
            optionFilterProp="txt"
            @onChangeSelectField="onSelectProductSupplier"
          />
        </aCol>

        <aCol :span="24">
          <HayaTextField
            label="Nome da fatura"
            placeholder="Escreva..."
            fieldKey="name"
            :required="true"
            :form="form"
          />
        </aCol>

        <aCol class="" :span="24">
          <div class="travel-input">
            <aFormItem>
              <label for="" class="filled">Período</label>
              <a-range-picker
                v-decorator="[
                  `period`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório.',
                      },
                    ],
                  },
                ]"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
            /></aFormItem>
          </div>
        </aCol>

        <aCol :span="24">
          <aButton
            type="primary"
            block
            html-type="submit"
            :loading="loadingCreateTempInvoice"
          >
            Cadastrar fatura temporária
          </aButton>
        </aCol>
      </aRow>
    </aForm>
  </section>
</template>

<script>
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";

export default {
  components: {
    HayaTextField,
    HayaSelectField,
  },
  mixins: [productSupplierMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      companiesList: [],
      companyBranchesList: [],
      loadingCreateTempInvoice: false,
      productSupplierName: "",
    };
  },
  mounted() {
    this.productSupplier.pagination.perPage = 300;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();

    this.$http.get(`/company/list?page=1&per_page=100`).then(({ data }) => {
      this.companiesList = data.data.map(({ id, trading_name }) => {
        return {
          label: `${id != 0 ? id + " - " : ""} ${trading_name} `,
          value: id,
        };
      });
    });
  },
  methods: {
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.product_supplier_name = this.productSupplierName;
        values.period = `${values.period[0]}|${values.period[1]}`;
        values.status = "Em edição";

        console.log(err, values);
        if (!err) {
          this.createTempInvoice(values);
        }
      });
    },
    getCompanyBranches(companyId) {
      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&show_in_contract=1&company_id=${companyId}`
        )
        .then(({ data }) => {
          this.companyBranchesList = data.data.map(({ id, name }) => {
            return {
              label: `${id != 0 ? id + " - " : ""} ${name} `,
              value: id,
            };
          });
        })
        .catch(() => {
          this.$message.error("Nenhuma filial encontrada.");
        });
    },

    getCompanyBranchesOnChange(id) {
      this.form.setFieldsValue({
        [`company_branch_id`]: undefined,
      });

      this.companyBranchesList = [];
      this.getCompanyBranches(id);
    },
    onSelectProductSupplier(a, b) {
      this.productSupplierName = b.data.attrs.obj.trading_name;
    },
    async createTempInvoice(payload) {
      this.loadingCreateTempInvoice = true;
      try {
        const data = await this.$http.post(
          `/temporary-invoice/create`,
          payload
        );
        this.$emit("onCreateLoadTempInvoicesList");
        return data;
      } catch (e) {
        e;
      } finally {
        this.loadingCreateTempInvoice = false;
      }
    },
  },
};
</script>
