<template>
  <section>
    <aDivider class="mb-0 mt-0" orientation="left">
      Faturas Temporárias
    </aDivider>

    <aTable
      class="travel-table"
      :columns="columns"
      :data-source="tempInvoice.list"
      :loading="tempInvoice.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
    >
      <div slot="product_supplier" slot-scope="record">
        <div class="dotted-phrase upper">
          <a-tooltip :title="record.product_supplier_name">
            {{ record.product_supplier_id }} -
            {{ record.product_supplier_name }}
          </a-tooltip>
        </div>
      </div>

      <div slot="company" slot-scope="record">
        <div class="dotted-phrase upper">
          {{ record.company.trading_name }}
        </div>
      </div>

      <div slot="company_branch" slot-scope="record">
        <div class="dotted-phrase upper">
          {{ record.company_branch.id }} - {{ record.company_branch.name }}
        </div>
      </div>

      <div slot="selected_paxes" slot-scope="record">
        {{ record.selected_paxes | formatSelectedPaxes }}
      </div>

      <div slot="period" slot-scope="record">
        {{ record.period | formatPeriod }}
      </div>

      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }} | Criado
            em:
            {{ record.created | formatDateTime }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="20"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
              :size="20"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <a
          v-if="record.status === 'Em edição'"
          class="edit"
          @click="selectPaxesTempInvoices(record)"
        >
          <aTooltip placement="left" title="Selecionar paxes">
            <aIcon class="f16" type="play-circle" />
          </aTooltip>
        </a>

        <aPopconfirm
          v-if="
            record.status === 'Em edição' &&
            formatSelectedPaxes(record.selected_paxes) === 0 &&
            ['1', '2', 1, 2].includes($store.state.userData.id)
          "
          placement="left"
          title="Tem certeza que deseja apagar?"
          ok-text="Sim"
          cancel-text="Não"
          @confirm="deleteTempInvoice(record.id)"
        >
          <aIcon class="f16 edit red ml-5 c-pointer" type="delete" />
        </aPopconfirm>
      </div>
    </aTable>
  </section>
</template>

<script>
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import formatThings from "@/mixins/general/formatThings.js";

export default {
  mixins: [productSupplierMixins, formatThings],
  data() {
    return {
      tempInvoice: {
        list: [],
        loading: false,
      },
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Tipo",
          dataIndex: "type",
          key: "type",
          width: 120,
          sorter: true,
        },
        {
          title: "Nome da fatura",
          dataIndex: "name",
          key: "name",
          width: 150,
          sorter: true,
        },
        {
          title: "Fornecedor",
          scopedSlots: { customRender: "product_supplier" },
          width: 160,
        },
        {
          title: "Empresa",
          scopedSlots: { customRender: "company" },
          width: 100,
        },
        {
          title: "Filial",
          scopedSlots: { customRender: "company_branch" },
          width: 160,
        },
        {
          title: "Período",
          scopedSlots: { customRender: "period" },
          width: 190,
        },
        {
          title: "Qtd. Paxes",
          scopedSlots: { customRender: "selected_paxes" },
          width: 100,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 150,
          sorter: true,
        },
        {
          title: "",
          scopedSlots: { customRender: "user" },
          width: 60,
          key: "user",
        },
        {
          title: "",
          scopedSlots: { customRender: "action" },
          width: 80,
          align: "right",
          fixed: "right",
        },
      ],
    };
  },
  mounted() {
    this.getTempInvoices();
  },
  filters: {
    formatPeriod(period) {
      let thePeriod = period.split("|");
      return `${thePeriod[0].split("-").reverse().join("/")} a ${thePeriod[1]
        .split("-")
        .reverse()
        .join("/")}`;
    },
    formatSelectedPaxes(arr) {
      return arr !== "" ? JSON.parse(arr).length : 0;
    },
  },
  methods: {
    selectPaxesTempInvoices(tempInvoice) {
      this.$emit("selectPaxesTempInvoices", tempInvoice);
    },
    formatSelectedPaxes(arr) {
      return arr !== "" ? JSON.parse(arr).length : 0;
    },
    async getTempInvoices(payload) {
      payload;
      try {
        const { data } = await this.$http.post(
          `/temporary-invoice/list?page=1&per_page=20&status=Em edição&order=desc&order-by=created`
        );

        this.tempInvoice.list = data.data;
        return data;
      } catch (e) {
        e;
        this.tempInvoice.list = [];
      } finally {
        //
      }
    },
    async deleteTempInvoice(id) {
      try {
        const { data } = await this.$http.post(
          `/temporary-invoice/delete?id=${id}`
        );
        this.getTempInvoices();
        return data;
      } finally {
        //
      }
    },
  },
};
</script>
